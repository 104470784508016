<template>
  <transition>
    <div v-show="visible" @click="backToTop">
      <slot>
        <v-btn class="j_button totop">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </slot>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'JTotop',
  props: {
    visibleoffset: {
      type: [String, Number],
      default: 300,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    scrollFn: {
      type: Function,
      default: function (eventObject) { },
    }
  },
  data() {
    return {
      visible: false
    }
  },
  mounted() {
    window.smoothscroll = () => {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
      }
    }
    window.addEventListener('scroll', this.catchScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.catchScroll)
  },
  methods: {
    /**
     * Catch window scroll event 
     * @return {void}
     */
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset)
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
      this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
      this.scrollFn(this)
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop() {
      window.smoothscroll()
      this.$emit('scrolled')
    }
  },
}
</script>
