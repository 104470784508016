<template>
  <div :id="localId" class="svg_wrapper">This is chart component.</div>
</template>

<script>
import mx_Core  from './includes/mixins/importer'

export default {
  name: "k-chart-p6-critical-summary",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    DataItems: { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  methods: {
    draw() {
    },
  }
}
</script>